@media print {
  .noPrint{
    display: none;
  }
}

@media screen {
  .onlyPrint{
    display: none;
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.paragraphPrint {
  display: none;
}

body {
  background-color: #182229;
  color: white;
  font-family: "FiraSans-Regular", sans-serif;
  height: 100%;
}

.App {
  text-align: left;
  display: inherit;
}
.App-MuiButton-colorPrimary{
  color:#f9a557;
}

.App-Select-icon{
  color: red;
}

h6 {
  color: #84898D;
}

.App-content
{
  background-color: #182229;
}

.App-link {
  color: #61dafb;
}

.App-Badge
{
  background-color: #f9a557;
}

/* Large devices (laptops/desktops, 600px and up) */
@media only screen and (min-width: 600px) {
  .header-image {
    content:url("../public/images/logo_icon.png");
    max-height: 50px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .header-image {
    max-height: 50px;
    max-width: 50px;
    content:url("../public/images/logo_icon.png");
  }
}

@font-face {
  font-family: 'FiraSans-Bold';
  src: url('../public/fonts/FiraSans-Bold.ttf')
}

@font-face {
  font-family: 'FiraSans-SemiBold';
  src: url('../public/fonts/FiraSans-SemiBold.ttf')
}

@font-face {
  font-family: 'FiraSans-Regular';
  src: url('../public/fonts/FiraSans-Regular.ttf')
}

body {
  margin: 0;
  font-family: "FiraSans-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
  /*height: 600px;*/
  height: 76vh;
}

.map-container {

}

.sidebar {
  /*background-color: rgb(35 55 75 / 90%);*/
  /*color: #fff;*/
  padding: 5px;
  font-family: monospace;
  z-index: 1;
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  margin: 10px;
  border-radius: 5px;
}

.map-taskables-panel
{
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgb(24 34 31 / 60%);
  color: #fff;
  padding: 5px;
  font-family: monospace;
  z-index: 1;
  margin: 10px;
  border-radius: 5px;
}

.map-taskable-details-panel
{
  position: absolute;
  top: 10px;
  left: 70%;
  background-color: rgb(24 34 31 / 60%);
  color: #fff;
  padding: 5px;
  font-family: monospace;
  z-index: 1;
  margin: 10px;
  border-radius: 5px;
}

